@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "banner__typography";

.banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.banner-outer-link {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 11;
}

.banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    @include media-breakpoint-up(md) {
        align-items: center;
        padding-left: 40px;
        position: absolute;
    }

    @include media-breakpoint-up(lg) {
        height: 50%;
        width: 50%;
    }

    &::before {
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.5;
        position: absolute;
        top: 0;
        z-index: -1;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.banner-content-container {
    margin-top: 24px;
    padding: 0 15px;

    @include media-breakpoint-up(md) {
        /* stylelint-disable-next-line */
        margin-top: 0px;
        padding: 0 30px;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 0;
    }
}

.banner-content-title {
    margin-bottom: 10px;
    width: 100%;
}

.banner-content-description {
    margin: 0;
    margin-bottom: 24px;
    width: 100%;

    @include media-breakpoint-up(md) {
        margin-bottom: 32px;
    }
}

.banner-buttons {
    display: flex;
    width: 100%;

    /* stylelint-disable-next-line */
    .btn {
        &:first-of-type {
            margin-right: 20px;
        }
    }
}

.experience-assets-banner .margins-enabled {
    margin-top: 15px;

    @include media-breakpoint-up(md) {
        margin-top: 32px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 21px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 31px;
    }
}

/* stylelint-disable-next-line */
.experience-layouts-up1ItemCarousel .margins-enabled {
    margin-top: 15px;

    @include media-breakpoint-up(md) {
        margin-top: 32px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 21px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 31px;
    }
}
