@import "bootstrap/scss/functions";
// flag icons location in relation to target location of the css file.

$grid-breakpoints: (
    xs: 0,
    sm: 375px,
    md: 768px,
    lg: 1200px,
    xl: 1440px,
    xxl: 1920px,
) !default;

$container-max-widths: (
    sm: 375px,
    md: 768px,
    lg: 1200,
    xl: 1440px,
    xxl: 1920px,
) !default;

:root {
    --font-primary: "Proxima Nova","Helvetica Neue", Helvetica, Arial, sans-serif;
    --font-secondary: "Playfair Display", Georgia, serif;
}

$border-radius: 0.1875rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;

// Tabs
$nav-tabs-border-width: 0 !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-active-link-hover-bg: transparent !default;

$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;


h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 32px;
    font-style: normal;
}

h4 {
    font-size: 26px;
}

h5 {
    font-size: 18px;
}
